// Services

.services-block {
  background: url(../img/cream-pixels.png) 0 0 repeat $lightest-grey;
  padding-top: 40px;
  padding-bottom: 20px;
  @include breakpoint(m) {
    padding-top: 60px;
  }
}

.service-list {
  list-style: none;
  padding: 0;
}
.service-item {
  margin: 0 0 30px 0;
  @include breakpoint(m) {
    margin: 0;
    min-height: 120px;
  }
}
.service-item {
  padding-left: 80px;
  position: relative;
  &:before {
    content: '';
    width: 50px;
    height: 50px;
    display: inline-block;
    position: absolute;
    top: 5px;
    left: 10px;
  }
}
.service-item-heading {
   @extend .fs-4;
   font-weight: 700;
}

.handyman:before { background: url(../img/driller.svg) 0 0 no-repeat; }
.building:before { background: url(../img/hammer.svg) 0 0 no-repeat; }
.home-maintenance:before { background: url(../img/measuring-tape.svg) 0 0 no-repeat; }
.custom-jobs:before { background: url(../img/set-square.svg) 0 0 no-repeat; }
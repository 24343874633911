// Header

.header {
  padding-top: 30px;
  padding-bottom: 20px;
  @include breakpoint(m) {
    border-bottom-color: transparent;
  }
}

.logo {
  width: 225px;
  height: 51px;
  background: url(../img/construct.svg) 50% 50% no-repeat;
  display: inline-block;
  transition: all .5s;
  cursor: pointer;
  &:hover {
    background: url(../img/construct-white.svg) 50% 50% no-repeat;
  }
}

.menu {
  list-style: none;
  margin: 0;
  padding: 30px 0 10px 0;
  @include breakpoint(m) {
    padding: 15px 0 0 0;
  }
}
.menu-item {
  padding: 0;
  display: inline-block;
  margin: 0 10px;
  & > a {
    font-weight: 500;
    color: $white;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      transition: all .2s;
      color: $yellow;
    }
  }
}
// Banner

.banner {
  background: url(../img/banner.jpg) 50% 50% no-repeat $black;
  background-size: cover;
  color: $white;
  text-align: center;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    @include background-alpha($black, 50%);
  }
  &:after {
    height: 200px;
    display: block;
    content: "";
    background: url(../img/banner.svg) 50% 50% no-repeat;
    background-size: cover;
    position: relative;
    top: 1px;
    z-index: 200;
  }
}
.banner-inner {
  position: relative;
  z-index: 100;
}

// Animations
.logo {
  @include animation(.8s);
  @extend .fade-in-left;
  animation-delay: 1s;  
}
.navigation {
  @include animation(.8s);
  @extend .fade-in-right;
  animation-delay: 1s;
}
.banner {
  @include animation(.8s);
  @extend .fade-in;
}
.banner-heading-text-1 {
  @include animation(1s);
  @extend .fade-in;
  animation-delay: 2s;
}
.banner-heading-text-2 {
  @include animation(1s);
  @extend .fade-in;
  animation-delay: 2.7s;
}
.banner-heading-text-3 {
  @include animation(1s);
  @extend .fade-in;
  animation-delay: 3.4s;
}
.banner-text-1 {
  @include animation(1s);
  @extend .fade-in;
  animation-delay: 3.7s;
}
.banner-button-1 {
  @include animation(.8s);
  @extend .fade-in-up;
  animation-delay: 4.1s;
}
.banner-button-2 {
  @include animation(.8s);
  @extend .fade-in-up;
  animation-delay: 4.3s;
}

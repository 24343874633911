// Website Colours
$black: #000;
$white: #fff;
$yellow: #ffc13b;
$light-yellow: #ffd069;
$cream: #fee19b;
$grey: #545454;
$dark-grey: #2e2e2e;
$darker-grey: #1f1f1f;
$darkest-grey: #161616;
$light-grey: #ccc;
$lighter-grey: #f3f3f3;
$lightest-grey: #f1f1f1;

$base-font-size: 16;
$base-line-height: 22;

// Website Variable Overrides
$base-font-family: "Roboto", sans-serif;
$base-heading-font-family: "Montserrat", sans-serif;

$base-font-size: 14;
$base-line-height: 20;

$base-font-color: #676767;
$base-link-color: #ff5717;
$base-link-hover-color: #ff5717;

$base-h1-font-size: 40;
$base-h1-line-height: 46;
$base-h1-font-weight: 700;
$base-h2-font-weight: 700;
$base-h2-font-size: 30;
$base-h2-line-height: 36;
$base-h3-font-weight: 500;
$base-h4-font-weight: 500;
$base-h5-font-weight: 500;
$base-h6-font-weight: 500;